import { Link } from "@StarberryUtils";
import React,{useState,useEffect} from "react"
import {Button, Form, Container, Row, Col,Fade} from 'react-bootstrap';
// Style
import  './ValuationOne.scss';

const ValuationOne = ( props ) => {
  const [ReadMoreText, ReadMoreLess] = useState(false);

  const ReadMore=()=>{
    ReadMoreLess(!ReadMoreText)
  }
  return(
    <React.Fragment>
    <div className="valuation-Banner">
      <Container>
        <Row>
        <Col lg="12">
          <div className="">
           <div className="valuation-heading">
           <h1>Get a free property valuation</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc dapibus nunc non nisl tempor consequat. Vestibulum lobortis consequat tortor lipsum dolor.</p>
           </div>
            <div className="valuation-block">
              <div className="valuation-box">
                <h2>Home Visit Valuation</h2>
                <span>An accurate valution from one of our team.</span>
                <ul>
                  <li>
                     Lorem ipsum dolor sit amet consectetur
                  </li>
                  <li>
                     Duis sed dignissim orci dictum lacus
                  </li>
                  <li>
                     Lorem ipsum dolor sit amet sed dignissim
                  </li>
                 
                </ul>
                <Link href="#" className="btn btn-primary">Book Appointment</Link>
              </div>

              <div className="valuation-box">
                <h2>Online Valuation</h2>
                <span>An accurate valution from one of our team.</span>
                <ul>
                  <li>
                     Lorem ipsum dolor sit amet consectetur
                  </li>
                  <li>
                     Duis sed dignissim orci dictum lacus
                  </li>
                  <li>
                     Lorem ipsum dolor sit amet sed dignissim
                  </li>
                  
                </ul>
                <Link href="#" className="btn btn-primary">Instant Valuation</Link>
              </div>
            </div>
          </div>
       </Col>
        </Row>
       
      </Container>
    </div>
      </React.Fragment>  
  
)
  }



export default ValuationOne